<template>
  <div style="height: 100vh; overflow: auto;">
    <div v-if="!isMobile">
      <div class="left">
        <div class="inner-left">
          <div class="inner-left2">
            <h1>O que aconteceu?</h1>
            <h5>Preencha os campos e nos ajude a melhorar</h5>
            <br />
            <p class="label-form">
              Qual o motivo para você se descadastrar da nossa lista?
            </p>
            <div id="inscricao-div">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="A frequência de e-mails está muito alta"
                  >A frequência de e-mails está muito alta</b-form-radio
                >
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="O conteúdo do e-mail não está interessante"
                  >O conteúdo do e-mail não está interessante</b-form-radio
                >
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="O e-mail tem algum conteúdo ofensivo"
                  >O e-mail tem algum conteúdo ofensivo</b-form-radio
                >
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="Não inscrevi o meu e-mail na lista"
                  >Não inscrevi o meu e-mail na lista
                </b-form-radio>
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="O e-mail enviado possui algum tipo de spam"
                  >O e-mail enviado possui algum tipo de spam</b-form-radio
                >
              </b-form-group>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div class="rigth">
        <div>
          <img src="@/assets/img/email.png" alt="" />
          <br />
          <div class="div-button">
            <!-- <div class="div-h4"> 
                        <h4>Você tem certeza que deseja se desiscrever?</h4>
                    </div> -->
            <BaseButton
              v-if="!sent"
              variant="outline-white"
              class="mr-4"
              @click="desiscrever"
            >
              Quero me descadastrar
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isMobile">
      <div class="leftMobile">
        <div class="inner-left" id="inner-left-mobile">
          <div class="inner-left2">
            <h1 id="h1Mobile">O que aconteceu?</h1>
            <h5 id="h5Mobile">Preencha os campos e nos ajude a melhorar</h5>
            <br />
            <div id="inscricao-div">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="A frequência de e-mails está muito alta"
                  >A frequência de e-mails está muito alta</b-form-radio
                >
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="O conteúdo do e-mail não está interessante"
                  >O conteúdo do e-mail não está interessante</b-form-radio
                >
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="O e-mail tem algum conteúdo ofensivo"
                  >O e-mail tem algum conteúdo ofensivo</b-form-radio
                >
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="Não inscrevi o meu e-mail na lista"
                  >Não inscrevi o meu e-mail na lista
                </b-form-radio>
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="O e-mail enviado possui algum tipo de spam"
                  >O e-mail enviado possui algum tipo de spam</b-form-radio
                >
                <b-form-radio
                  v-model="selected"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="Outro motivo"
                  >Outro motivo</b-form-radio
                >
              </b-form-group>
            </div>
            <br />
          </div>
        </div>
      </div>
      <div class="rigthMobile">
        <div>
          <img src="@/assets/img/email.png" alt="" id="imgMobile" />
          <br />
          <div class="div-button">
            <!-- <div class="div-h4"> 
                        <h4>Você tem certeza que deseja se desiscrever?</h4>
                    </div> -->
            <BaseButton
              v-if="!sent"
              variant="outline-white"
              class="mr-4"
              @click="desiscrever"
            >
              Desinscrever
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import FunnelService from "@/services/resources/FunnelService.js";
const serviceFunnel = FunnelService.build();
export default {
  data() {
    return {
      client: {
        width: 0,
      },
      selected: "",
      sent: false,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    desiscrever() {
      const urlParams = new URLSearchParams(window.location.search);
      const lead = urlParams.get("lead");
      if (this.selected !== "") {
        var data = {
          id: "email/unsubscribe",
          email: lead,
          motivo: this.selected,
          _: this.$route.params.tenant_id,
          camp: urlParams.get("camp")
        };

        serviceFunnel
          .createId(data)
          .then(() => {
            this.$grToast.toast(
              "Vocẽ não faz mais parte dessa lista de e-mails",
              {
                title: "Descadastrado com sucesso",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.sent = true;
            document
              .querySelectorAll("[name=some-radios]")
              .forEach((select) => {
                select.disabled = true;
              });
          })
          .catch(() => {
            this.$grToast.toast("Erro ao desinscrever", {
              title: "Erro ao desinscrever",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      } else {
        this.$grToast.toast("Saber sua opnião é muito importante para nós", {
          title: "Selecione um motivo",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$store.getters.user.user.level == "blogger") {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
  },
  watch: {
    selected() {
      // console.log(this.selected);
    },
  },
};
</script>
  
<style lang="scss">
// Remove o crisp e o userreport
body:has(.inner-left2) {
  div:has(#crowd-shortcut) {
    display: none !important;
  }
  .crisp-client {
    display: none !important;
  }
}


.left {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #a9ace0;
  padding: 3vh;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.leftMobile {
  width: 100vw;
  background-color: #a9ace0;
  padding: 3vh;
}
.rigth {
  position: absolute;
  left: 40vw;
  top: 0px;
  width: 60vw;
  height: 100vh;
  background-color: #171a3f;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.rigthMobile {
  width: 100vw;
  height: 60vh;
  background-color: #171a3f;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.inner-left {
  background-color: rgba(255, 255, 255, 0.288);
  border-radius: 20px;
  border: 2px solid white;
  width: 100%;
  padding: 3vw;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
#inner-left-mobile {
  padding: 6vw;
}

.label-form {
  font-weight: 600;
}
.custom-control.custom-radio {
  background: #f7f7f7;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
}

#inscricao-div .custom-control-label {
  margin-bottom: 0 !important;
}

label.custom-control-label {
  margin-left: 25px;
  max-width: 95%;
}
.custom-control.custom-radio > value {
  margin-left: 25px;
  cursor: pointer;
  margin-bottom: 0 !important;
  width: 100%;
  margin-bottom: 5px;
}

.div-button {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.div-h4 {
  width: 100%;
}

h1 {
  font-size: 2vw;
  text-align: center;
}
h5 {
  font-size: 1vw;
  text-align: center;
}

#h1Mobile {
  font-size: 7vw;
}
#h5Mobile {
  font-size: 4vw;
}
img {
  width: 28vw;
  height: 28vw;
  margin-bottom: 6vh;
}

#imgMobile {
  width: 60vw;
  height: 60vw;
  margin-bottom: 6vh;
}

textarea,
#form-modificado {
  padding: 15px 20px !important;
  color: #000 !important;
  outline: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 15px !important;
}

// notificações
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 10px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}
.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}
.b-toaster,
.swal2-container {
  z-index: 9999999 !important;
}
.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.toast-body {
  color: #5f5c6b !important;
}
.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}
.toast-header strong::before {
  content: "";
  background: url("../assets/img/icons/notify_default.svg") no-repeat center
    center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}
// sucesso
.b-toast-success .toast-header strong {
  color: var(--greenn);
}
.b-toast-success .toast-header strong::before {
  background: url("../assets/img/icons/notify_success.svg") no-repeat center;
}
.b-toast-success .toast {
  background-color: #f2fefa !important;
  border-color: var(--greenn) !important;
  color: var(--greenn) !important;
}
.b-toast-success .toast .toast-header {
  color: var(--greenn) !important;
  background-color: #f2fefa !important;
  border: none !important;
}
.b-toast-success.b-toast-solid .toast {
  background-color: #f2fefa !important;
}
// danger
.b-toast-danger .toast-header strong {
  color: var(--red);
}
.b-toast-danger .toast-header strong::before {
  background: url("../assets/img/icons/notify_danger.svg") no-repeat center;
}
.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: var(--red) !important;
  color: var(--red) !important;
}
.b-toast-danger .toast .toast-header {
  color: var(--red) !important;
  background-color: #fff3f5 !important;
  border: none !important;
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}
</style>